#footerNav {
  max-width: 400px;
  min-width: 250px;
  align-self: center;
  width: 100%;
  height: 2.5rem;
  margin-top: 2.5rem;
  @include smallscreen {
    margin-bottom: 0.5rem;
  }
  @include desktop {
    max-width: 500px;
  }
  & > ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--body-font);
    list-style-type: none;
    font-weight: 500;
    padding: 0 20px;
    margin: 0;

    li.code {
      text-transform: uppercase;
      letter-spacing: 1px;
      line-height: 1;
      & > span {
        &:before,
        &:after {
          text-transform: initial;
          font-size:1.1rem;
          z-index: -1;
          line-height: 1;
        }
        &:before {
          left: -10px;
          top: -15px;
          content: "<a>";
          @include desktop {
            top: -20px;
          }
        }
        &:after {
          content: "</a>";
          left: -10px;
          bottom: -15px;
          @include desktop {
            bottom: -20px;
          }
        }
        @include tablet {
          .contact-color:hover {
            border-bottom: 2px solid var(--contact-color);
            transition: border .25s ease-in-out;
          }
          .skills-color:hover {
            border-bottom: 2px solid var(--skills-color);
            transition: border .25s ease-in-out;
          }
          .about-color:hover {
            border-bottom: 2px solid var(--about-color);
            transition: border .25s ease-in-out;
          }
          .contact-color:hover {
            border-bottom: 2px solid var(--contact-color);
            transition: border .25s ease-in-out;
          }

        }
      }
    }
  }
}
