body.dark {
    --body-background: #000C1D;
    --body-font: #ffffff;
    --blurb-font: #ffffff;
    --accent-color: #102A44;
    --code-color: #364964;
    --about-color: #7FDBCA;
    --contact-color: #C792EA;
    --skills-color: #ADDB67;
    --hover-color: #000C1D;
}

body.light {
    --body-background: #FAFAFA;
    --body-font: #102A44;
    --blurb-font: #666666;
    --accent-color: #F2F2F2;
    --code-color: #D6D6D6;
    --about-color: #5E85A1;
    --contact-color: #C6777A;
    --skills-color: #666666;
    --hover-color: #FAFAFA;
}