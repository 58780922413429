
.burger {
    --smallwidth: 8px;
    --speed: 0.4s;
    --delay: 0.3s;
    --othertransition: opacity calc(var(--speed)) ease-in, width calc(var(--speed)/2) ease-in, background-color .15s ease-in-out;
    width: 35px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    @include tablet {
        &:hover {
            .burger__line-1, .burger__line-2, .burger__group > .burger__line-4, .burger__line-5 {
                border-radius: 0 !important;
                width: 100%;
                transition: all .4s ease-in-out;
            }
    
            .burger__group > .burger__line-3{
                width: 0;
                border-radius: 0 !important;
                transition: all .4s ease-in-out;
            }
        }
    }
    
    .burger__child {
        height: 4px;
        width: 100%;
        background-color: var(--about-color);
        border-radius: 10px;
        opacity: 1;

    }
    & > .burger__line-1 {
        width: 29px;
        align-self: flex-end;
        transform-origin: -3px 6px;
        transition: all var(--speed) ease-in-out;

    }
    .burger__line-2 {
        width: var(--smallwidth);
        align-self: flex-end;
        transition: var(--othertransition);
        transition-delay: var(--delay);
    }
    .burger__group {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &> .burger__child {
            width: var(--smallwidth);
        }
    }

    .burger__line-3 {
        transition: all .2s ease-out;
        transition-delay: var(--delay);
    }

    .burger__line-4 {
        transition: var(--othertransition);
        transition-delay: var(--delay);
    }
    .burger__line-5 {
        width: 23px;
        align-self: center;
        transform-origin: 34px 12px;
        transition: all var(--speed) ease-in-out;
    }

    &.open {
        &>.burger__line-1 {
            width: 120%;
            transform: rotate(45deg) translateX(3px) translateY(-9px);
            transition: all var(--speed) ease-in-out;
            transition-delay: var(--delay);
        }
        .burger__line-4,.burger__line-2 {
            opacity: 0;
            width: 100%;
            transition: var(--othertransition);
        }

        .burger__line-3 {
            width: 0;
            opacity: 0;
            transition: var(--othertransition);
        }
        &>.burger__line-5 {
            width: 120%;
            transform: rotate(-45deg) translateX(20px) translateY(-15px);
            transition: all var(--speed) ease-in-out;
            transition-delay: var(--delay);
        }

    }
}

.burger-overlay {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: -1;
    background-color: var(--accent-color);
    transition: all .4s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    transition-delay: 0.9s;

    &.open {
        opacity: 1;
        transition: all 0.3s ease-out;
        pointer-events: all;

        &>.burger-overlay__nav {
            ul li:nth-child(1) > a.nav-item {
                transition-delay: 0.3s;
                &:hover {
                    transition-delay: 0.0001s;
                }
            }
            ul li:nth-child(2) > a.nav-item {
                transition-delay: 0.5s;
                &:hover {
                    transition-delay: 0.0001s;
                }
            }
            ul li:nth-child(3) > a.nav-item {
                transition-delay: 0.7s;
                &:hover {
                    transition-delay: 0.0001s;
                }
            }
            ul li:nth-child(4) > a.nav-item {
                transition-delay: 0.9s;
                &:hover {
                    transition-delay: 0.0001s;
                }
            }
            @include tablet {
                ul li > a.nav-item:hover {
                    color: var(--contact-color);
                    border-bottom: .5rem var(--contact-color) solid;
                    font-size: 3.1rem;
                    transition: color .25s ease-in-out, font-size .25s ease-in-out, opacity .2s ease-in, margin .2s ease-in, border-width .2s ease-in-out;
                }
            }

            
            .nav-item {
                opacity: 1;
                margin-bottom: 1rem;
                transition: opacity .5s ease-in, margin .5s ease-in;
            }
        }

    }
 
    .burger-overlay__nav {
        ul {
            list-style: none;
            padding: 0;
            li {
                height: 6rem;
                display: flex;
                justify-content: center;
                align-items: center;
                &:nth-child(1) > a.nav-item {
                    transition-delay: 0.6s;
                }
                &:nth-child(2) > a.nav-item {
                    transition-delay: 0.4s;
                }
                &:nth-child(3) > a.nav-item {
                    transition-delay: 0.2s;
                }
                &:nth-child(4) > a.nav-item {
                    transition-delay: 0.0001s;
                }
                a.nav-item {
                    text-align: center;
                    font-size: 3rem;
                    font-weight: bold;
                    font-family: "Roboto Slab", Palatino, Georgia, serif;
                    line-height: 1;
                    text-align: center;
                    margin-bottom: 0;
                    color: var(--body-font);
                    opacity: 0;
                    border-bottom: 0 var(--accent-color) solid;
                    transition: color 1s ease-in-out, font-size .5s ease-in-out, opacity .2s ease-in, margin .2s ease-in, border-width 1s ease-in-out;
                    
                }
            }
        }
    }
}