body {
  
  #mainContent {
      #home {
        
      --pageTheme: var(--body-font);
      @include tablet {
        a:hover {
          border-bottom: .2rem currentColor solid;
          transition: border .25s ease-in-out;
        }
      }
      .home__title {
        margin-top: 10px;
        margin-bottom: 2.14rem;
        font-weight: bold;
        font-size: 5.7rem;
        line-height: 1;

        & > span {
          color: var(--pageTheme);

          &:before {
            content: "<h1>";
            font-size: 20px;
            left: -17px;
            top: -5px;
            @include desktop {
              top: 10px;
            }
          }
          &:after {
            content: "</h1>";
            font-size: 20px;
            right: -50px;
            bottom: 25px;
            @include desktop {
              bottom: 37px;
            }
          }
        }
      }

      .home__blurb {
        font-weight: normal;
        font-size: 1.7rem;
        color: var(--pageTheme);
        margin-bottom: 0.2rem;
        p {
          margin: 0;
          line-height: 150%;
        }
 
      }

      .home__subtitle {
        color: var(--skills-color);
        font-size: 1.2rem;

        &__svg {
          display: inline-block;
          margin-left: 6px;
          margin-right: 5px;
        }
      }
    }
  }
}
