#mainContent {
  #about {
    --pageTheme: var(--about-color);
    .title {
    }

    .about__blurb {
      color: var(--blurb-font);
      font-family: "Roboto Slab", Palatino, Georgia, serif;
      font-weight: normal;
      font-size: 1.14rem;
      line-height: 1.5;
      min-width: 200px;
      width: 90%;
      max-width: 275px;
      margin-bottom: 3.5rem;
      @include tablet {
        max-width: 320px;
      }
      @include desktop {
        max-width: 600px;
      }
    }
  }
}
