@mixin tablet {
  @media(min-width: 767px) {
      @content;
  }
}


@mixin desktop {
  @media (min-width: 1200px) {
      @content;
  }
}

@mixin smallscreen {
  @media (max-width: 370px) {
      @content;
  }
}

:root {
    font-size: 14px;
    @include smallscreen {
        font-size: 12px;
    }
    @include desktop {
        font-size: 20px;
    }
}



html,
#layout,
#root {
  height: 100vh;
}

body {
  background-color: var(--body-background);
  margin: 0;
  line-height: 150%;
  min-height: 100%;
  transition: color 1s ease-in-out;
  box-sizing: border-box;
  
}

.transition {
  transition: background-color 1s ease-in-out,
}


a {
  text-decoration: none;
  color: currentColor;

}

