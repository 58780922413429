#mainContent {
  #skills {
    --pageTheme: var(--skills-color);
    .title {
      & > span.codewords {
        display: inline-block;
        &:before {
          top: -20px;
        }
        &:after {
          content: "</h1>";
          font-size: 20px;
          right: -50px;
          bottom: 5px;

          @include desktop {
            bottom: 10px;
          }
        }
      }
    }
    .skills__blurb {
      color: var(--blurb-font);
      font-family: "Roboto Slab", Palatino, Georgia, serif;
      font-weight: normal;
      font-size: 1.14rem;
      line-height: 1.5;
      min-width: 200px;
      width: 90%;
      max-width: 275px;
      margin-bottom: 3.5rem;
      @include tablet {
        max-width: 320px;
        a.contact-color{
          transition: border .25s ease-in-out;
          &:hover {
          border-bottom: 2px solid var(--contact-color);
          
        }
      }
      }
      @include desktop {
        max-width: 600px;
      }
    }
  }
}
