header.headerNav {
    color: var(--about-color);
    display: flex;
    align-items: center;
    height: 60px;
    background-color: var(--accent-color);
    transition: background-color 1s ease-in, color 1s ease-out;
    transition-delay: 0s;
    justify-content: space-between;
    padding: 0 20px;
    z-index: 2;
    .isActive {
        border: none;
    }

    .headerNav__mode, .headerNav__burger {
        width: 50px; 
    }

    .headerNav__mode {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .headerNav__burger {
        display: flex;
        justify-content: flex-end;
    }

    .headerNav__wordmark {
        font-size: 24px;
        font-family: "Roboto Slab", Palatino, Georgia, serif;
        font-weight: bold;
        color: currentColor;

        .punctuation {
            color: var(--contact-color);
        }
    }

}