body {
  #skills-interactive {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    right: 0;
    bottom: 0;
    overflow: hidden;
    transition: opacity 1s ease-in-out;

    @include desktop {
      overflow: visible;
      position: static;
      width: auto;
    }
    &__svg {
      position: absolute;
      left: calc(34vw);
      top: calc(100px + 4vh);
      width: calc(60vh - 15vw);
      @include smallscreen {
        width: calc(55vh - 15vw);
        top: 24%;
      }
      @include desktop {
        width: 50vh;
        position: static;
      }
    }
    > svg path {
      transition: fill-opacity 1s ease-in-out, opacity 1s ease-out,
        stroke-width 0.25s ease-in-out, stroke-color 1s ease-out;
    }
  }
  #jeffrey-interactive {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    right: 0;
    bottom: 0;
    overflow: hidden;
    transition: opacity 1s ease-in-out;

    @include desktop {
      overflow: visible;
      position: static;
      width: auto;
    }

    > svg path {
      transition: fill-opacity 1s ease-in-out, opacity 1s ease-out,
        stroke-width 0.25s ease-in-out, stroke-color 1s ease-out;
    }

    &__svg {
      position: absolute;
      right: -10%;
      bottom: calc(20vh - 80px);
      width: calc(40vh - 50px);
      padding-bottom: 20px;

      @include smallscreen {
        bottom: 12%;
      }
      @include tablet {
        width: 40vh;
        left: 55vw;
        // bottom: 10%;
        padding-bottom: 0;
      }

      @include desktop {
        overflow: visible;
        position: static;
      }
    }
  }
  #jeffrey-interactive,
  #skills-interactive {
    @include desktop {
      position: static;
      width: auto;
    }

    &__svg {
      @include desktop {
        position: static;
      }
    }
  }
  &.dark {
    #jeffrey-interactive,
    #skills-interactive > svg path {
      stroke: #102a44;
      stroke-width: 4;
      stroke-opacity: 1;
    }
    #jeffrey-interactive path {
      &.rightearhole,
      &.leftearhole,
      &.mouth {
        stroke: none;
        stroke-width: 0;
      }
      &.mouth {
        fill: #102a44;
      }
    }
  }

  &.light {
    --shirt: #c22f33;
    --backpant: #032a34;
    --frontpant: #153743;
    --skin: #f2bf99;
    --darkskin: #e98349;
    --dark: #030304;
    --grey: #f1f2f2;
    --white: #ffffff;

    --darkblue: #132a43;
    --lightblue: #5e85a1;
    --lightred: #c6767a;

    #jeffrey-interactive,
    #skills-interactive {
      opacity: 0.3;
      @include tablet {
        opacity: 0.7;
        &:hover {
          opacity: 1;
          transition: 0.25s opacity ease-in;
        }
      }

      // jeffrey interactive
      path.lefthand {
        fill: var(--skin);
        stroke: var(--skin);
      }
      path.leftleg {
        fill: var(--backpant);
        stroke: var(--backpant);
      }
      path.leftarm {
        fill: var(--shirt);
        stroke: var(--shirt);
      }
      path.rightleg {
        fill: var(--frontpant);
        stroke: var(--frontpant);
      }
      path.shirt {
        fill: var(--shirt);
        stroke: var(--shirt);
      }
      path.righteye {
        fill: var(--white);
      }
      path.rightpupil {
        fill: var(--dark);
      }
      path.rightbrow {
        fill: var(--dark);
      }
      path.lefteye {
        fill: var(--white);
      }
      path.leftpupil {
        fill: var(--dark);
      }
      path.leftbrow {
        fill: var(--dark);
      }
      path.leftnose {
        fill: var(--darkskin);
      }
      path.rightnose {
        fill: var(--darkskin);
      }
      path.righthand {
        fill: var(--skin);
        stroke: var(--skin);
      }
      path.rightarm {
        fill: var(--shirt);
        stroke: var(--shirt);
      }
      path.mouth {
        fill: var(--darkskin);
      }
      path.face {
        fill: var(--skin);
        stroke: var(--skin);
      }
      path.rightear {
        fill: var(--skin);
        stroke: var(--skin);
      }
      path.rightearhole {
        fill: var(--darkskin);
      }
      path.leftear {
        fill: var(--skin);
        stroke: var(--skin);
      }
      path.leftearhole {
        fill: var(--darkskin);
        stroke: var(--darkskin);
        stroke-width: 1px;
      }
      path.hair {
        fill: var(--dark);
        stroke: var(--dark);
        transform: translateX(-1px);
      }
      // skills interactive

      path.windowtop {
        fill: var(--darkblue);
      }

      path.windowbottom {
        fill: var(--lightblue);
      }

      path.brush {
        fill: var(--lightred);
      }

      path.codesymbol {
        fill: var(--darkblue);
      }

      path.skillface {
        fill: var(--skin);
      }

      path.skillhair {
        fill: var(--dark);
      }
      path.skilllefthair {
        fill: var(--dark);
      }
    }
  }
}
