#mainContent {
  #contact {
    width: calc(100% - 50px);
    --pageTheme: var(--contact-color);
    @include desktop {
        flex-direction: row;
        width: 100%;
    }
    .title {
        @include tablet {
            width: 100%;
        }

        @include desktop {
            width: 40%;
        }
    }

    &-form {
      display: flex;
      flex-direction: column;
      width: 100%;

      @include desktop {
          width: 60%;
      }
      label {
        color: var(--contact-color);
        margin-top: 1rem;
        margin-bottom: 0.25rem;
        font-size: 1rem;
        line-height: 1.5;
        font-family: "Roboto Slab", Palatino, Georgia, serif;
        &:first-child {
          margin-top: 0;
        }
      }

      .contact__form-field {
        background-color: transparent;
        border: 2px solid var(--about-color);
        font-size: 1.4rem;
        transition: border .25s ease-in;
        padding: 7px 10px;
        text-decoration: none;
        font-family: "Roboto Slab", Palatino, Georgia, serif;
        &:focus {
          border: 2px solid var(--contact-color);
        }
      }
      input[type="email"] {
      }
      input[type="email"], textarea {
        color: var(--contact-color);
        width:100%;
        box-sizing: border-box;
      }

      textarea{
          height: 20vh;
          width: 100%;
          resize: none;
          box-sizing: border-box;
          @include tablet {
              height: 250px;
          }
      }
      &-button {
        margin-top: 3.5rem;
        margin-bottom: 3rem;
        cursor: pointer;
      }
      p.errorMsg {
        font-family: "Caveat", "Lucida Handwriting", cursive;
        color: var(--skills-color);
        font-size: 1.1rem;
        margin: 0;
        margin-top: 2rem;
      }
    }
  }
}
