#mainContent {
  padding-top: 4.3rem;
  flex-grow: 1;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;

  @include tablet {
    
  }

  @include desktop {
    display: flex;
    justify-content: center;

  }
  &>#home,#about,#contact,#skills {
    &.fade-appear {
      opacity: 0;
    }
    &.fade-appear-done {
      transition: opacity 1000ms ease-in;
      opacity: 1;
    }
  }
  &>#skills,#home,#about,#contact {
    
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0 25px;

    &.fade-enter {
        opacity: 0;
        transition: opacity 500ms ease-in;
        transition-delay: 750ms;
    }
    
    &.fade-enter-active {
        opacity: 1;
        // transition: opacity 500ms ease-in;
        // transition-delay: 500ms;
    }
    
    &.fade-exit {
        opacity: 1;
        transition: opacity 750ms ease-in;
    }
    
    &.fade-exit-active {
        opacity: 0;
        // transition: opacity 1000ms ease-in;
    }
  }
}

.isActive {
  pointer-events: none;
  border-bottom: 2px solid currentColor;
  transition: border .25s ease-in;
}

.content {
  @include tablet {
    // padding-left: 10%;
    // padding-top: 20%;
    display: flex;
    flex-direction: column;
    padding-top: 40px;
  }
  @include desktop {
    max-width: 1100px;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    padding: 0;
  }
}

#layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.code > .codewords {
  position: relative;
}
.code .codewords:before,
.code .codewords:after {
  content: "";
  color: var(--code-color);
  position: absolute;
  font-family: "Caveat", "Lucida Handwriting", cursive;
  font-size: 14px;
}

.title {
  color: var(--pageTheme);
  font-size: 3.42rem;
  line-height: 1.1;
  margin: 0 0 2.4rem 0;
  position: relative;
  & span.codewords {
    &:before {
      content: "<h1>";
      font-size: 20px;
      left: -15px;
      top: -10px;
    }
    &:after {
      content: "</h1>";
      font-size: 20px;
      bottom: 10px;
      @include desktop {
         bottom: 20px;
      }
    }
  }
}
.code {
  a.box-button.codewords, button.box-button.codewords  {
    border: 2px solid var(--pageTheme);
    background-color: unset;
    color: var(--pageTheme);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.42rem;
    width: 14.3rem;
    height: 3.72rem;
    letter-spacing: 1px;
    position: relative;
    max-height: 62px;
    max-width: 250px;
    transition: background-color .5s ease-in-out, color .5s ease-in-out, font-size .5s ease-in-out;
    @include tablet {
      &:hover {
        background-color: var(--pageTheme);
        color: var(--hover-color);
        font-size: 1.6rem;
      }
    }
      &:before {
        @include smallscreen {
          content: "";
        }
        content: "<button>";
        font-size: 20px;
        left: -20px;
        top: -2rem;
      }
      &:after {
        @include smallscreen {
          content: "";
        }
        content: "</button>";
        font-size: 20px;
        left: -20px;
        bottom: -2rem;
      }
  }
}


.punctuation {
  font-family: "Roboto", Times, "Times New Roman", serif;
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.contact-color {
  color: var(--contact-color);
}

.about-color {
  color: var(--about-color);
}

.skills-color {
  color: var(--skills-color);
}
