@font-face {
    font-family: "Caveat";
    font-weight: normal;
    src: local("Caveat Regular"), local("Caveat-Regular"),
      url("../fonts/caveat-regular-webfont.woff2");
      font-display: swap;
  }
  
  @font-face {
    font-family: "Roboto Slab";
    font-weight: bold;
    src: local("Roboto Slab"), local("Roboto-Slab"),
      url("../fonts/RobotoSlab-Bold.woff2");
      font-display: swap;
  }
  @font-face {
    font-family: "Roboto Slab";
    font-weight: normal;
    src: local("Roboto Slab"), local("Roboto-Slab"),
      url("../fonts/RobotoSlab-Regular.woff2");
      font-display: swap;
  }
  
  @font-face {
      font-family: "Poppins";
      font-weight: bold;
      src: local("Poppins"), url("../fonts/Poppins-Bold.woff2");
      font-display: swap;
  }
  @font-face {
      font-family: "Poppins";
      font-weight: normal;
      src: local("Poppins"), url("../fonts/Poppins-Regular.woff2");
      font-display: swap;
  }
  @font-face {
      font-family: "Poppins";
      font-weight: 500;
      src: local("Poppins"), url("../fonts/Poppins-Medium.woff2");
      font-display: swap;
  }