@mixin desktop {
  @media (min-width: 1200px) {
      @content;
  }
}
@mixin tablet {
  @media(min-width: 767px) {
      @content;
  }
}
body.dark {
  div.sun {
    background-color: #102a44;
    right: 0;
    top: 0;
  }
  div.moon {
    background-color: #364964;
    border: 2px solid #364964;
  }
}

body.light .modeicon:hover {
  @include tablet {
    transition-delay: 0.5s;
    div.sun {
      background-color: #f2f2f2;
      right: 0;
      top: 0;
    }
    div.moon {
      background-color: #364964;
      border: 2px solid #364964;
    }
  }
}

body.light{
  div.sun {
    background-color: #ffc063;
    right: 6.3px;
    top: 5.7px;
  }
  div.moon {
    border: 2px solid #ffc063;
    width: 34px;
    height: 34px;
  }
}

body.dark .modeicon:hover {
  @include tablet {
    transition-delay: 0.5s;
    div.sun {
      background-color: #ffc063;
      right: 6.3px;
      top: 5.7px;
    }
    div.moon {
      border: 2px solid #ffc063;
      width: 34px;
      height: 34px;
    }
  }
}

.modeicon {
  width: 35px;
  position: relative;
  height: 35px;
  cursor: pointer;
  div.sun {
    position: absolute;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    transition: all 1s ease-in-out;
    transform-origin: center center;
    box-sizing: border-box;
  }
  div.moon {
    position: absolute;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    transition: all 0.5s ease-in-out;
    transform-origin: center center;
    transition-delay: 0.5s;
    box-sizing: border-box;
  }
}
